import Vue from 'vue';

import VueCompositionApi from '@vue/composition-api';
Vue.use(VueCompositionApi);

import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css';
import { UserVuetifyPreset } from 'vuetify';

Vue.use(Vuetify);

const vuetifyOptions: UserVuetifyPreset = {
  theme: {
    themes: {
      light: {
        primary: '#2780E3',
        secondary: '#333333',
        'popup-header': '#333333'
      }
    },
    options: {
      customProperties: true
    }
  },
  breakpoint: {
    thresholds: {
      xs: 580
    },
    mobileBreakpoint: 800
  },
  icons: {
    iconfont: 'mdi',
    values: {
      add: 'mdi-plus',
      import: 'mdi-publish',
      menu_down: 'mdi-menu-down',
      tune: 'mdi-tune',
      sort: 'mdi-sort',
      more_vert: 'mdi-dots-vertical',
      arrow_left: 'mdi-chevron-left',
      object: 'mdi-developer-board',
      landmark: 'mdi-flag-variant',
      geozone: 'mdi-vector-polygon',
      group: 'mdi-label',
      group_remove: 'mdi-label-off',
      user: 'mdi-account',
      user_group: 'mdi-account-multiple',
      settings: 'mdi-cog',
      delete: 'mdi-delete',
      edit: 'mdi-pencil',
      account: 'mdi-account-circle',
      alert: 'mdi-bell',
      alert_on: 'mdi-bell-ring',
      alert_plus: 'mdi-bell-plus',
      whatsapp: 'mdi-whatsapp',
      message_alert: 'mdi-message-alert',
      message: 'mdi-message-text',
      exit_to_app: 'mdi-exit-to-app',
      access: 'mdi-security',
      colors: 'mdi-palette',
      mute: 'mdi-volume-off',
      unmute: 'mdi-volume-high',
      notice: 'mdi-volume-high',
      disable: 'mdi-cancel',
      marker_edit: 'mdi-map-marker-down',
      marker_set: 'mdi-map-marker',
      export: 'mdi-swap-vertical',
      history: 'mdi-history',
      download: 'mdi-download',
      copy: 'mdi-content-copy',
      star_off: 'mdi-star-outline',
      star_on: 'mdi-star',
      close: 'mdi-close',
      back: 'mdi-arrow-left',
      location_city: 'mdi-city',
      unlink: 'mdi-reply',
      set_default: 'mdi-reply',
      battery_full: 'mdi-battery',
      battery_empty: 'mdi-battery-alert',
      battery_dc: 'mdi-power-plug',
      battery_unknown: 'mdi-battery-unknown',
      status_no_response: 'mdi-cloud-off-outline',
      status_active: 'mdi-cloud-outline',
      status_error: 'mdi-sync-alert',
      status_requested: 'mdi-sync',
      status_processing: 'mdi-clock-outline',
      emulation: 'mdi-blur',
      qrcode: 'mdi-qrcode',
      send: 'mdi-send',
      down: 'mdi-arrow-down',
      calendar: 'mdi-calendar',
      check: 'mdi-check',
      gps_fixed: 'mdi-crosshairs-gps',
      gps_not_fixed: 'mdi-crosshairs',
      layers: 'mdi-layers',
      home: 'mdi-home',
      radio_checked: 'mdi-radiobox-marked',
      radio_unchecked: 'mdi-radiobox-blank',
      checkbox_unchecked: 'mdi-checkbox-blank-outline',
      checkbox_checked: 'mdi-checkbox-marked',
      circle: 'mdi-checkbox-blank-circle',
      plan: 'mdi-map',
      enabled: 'mdi-check-circle-outline',
      size: 'mdi-ruler',
      rotation: 'mdi-screen-rotation',
      uuid: ' mdi-identifier',
      tree: 'mdi-file-tree',
      schema: 'mdi-card-bulleted',
      execute: 'mdi-play-circle-outline',
      geotag: 'mdi-map-marker-radius',
      min_altitude: 'mdi-arrow-collapse-down',
      max_altitude: 'mdi-arrow-collapse-up',
      base_altitude: 'mdi-arrow-expand-vertical',
      country: 'mdi-earth',
      city: 'mdi-map-marker-radius',
      building: 'mdi-office-building-marker',
      door: 'mdi-door',
      postcode: 'mdi-email',
      change_form: 'mdi-vector-square-edit',
      move: 'mdi-edit-location',
      link_off: 'mdi-link-variant-off',
      dataset: 'mdi-puzzle',
      apps: 'mdi-apps',
      view_toggle: 'mdi-view-split-vertical',
      connection: 'mdi-wifi-arrow-up-down',
      password: 'mdi-key',
      track: 'mdi-map-marker-path',
      animate_track: 'mdi-motion-play-outline',
      play: 'mdi-play',
      pause: 'mdi-pause',
      stop: 'mdi-stop',
      skip_forward: 'mdi-skip-forward',
      skip_backward: 'mdi-skip-backward',
      radius: 'mdi-radius-outline',
      email: 'mdi-at',
      time: 'mdi-weather-cloudy-clock',
      view: 'mdi-eye',
      update: 'mdi-update'
    }
  }
};
const vuetify = new Vuetify(vuetifyOptions);

export { vuetify };
