import { usePopup } from '@/compositions/popup/index.ts';
import { computed } from '@vue/composition-api';

export default function(items) {
  const popup = usePopup();

  const titleWithCount = (objects = [], name) => `${name} (${objects.length})`;

  const addObjectGroupItem = {
    id: 'add_objectgroup',
    title: 'Add group',
    icon: '$add',
    action: () => {
      popup.open({
        component: () =>
          import('@/modules/object-groups/ui/ObjectGroupCreate.vue')
      });
    }
  };
  return {
    items: computed(() => [
      ...items.value.map(({ id, name, objectsWithinGroup }) => ({
        id,
        title: titleWithCount(objectsWithinGroup, name),
        type: 'MenuItemDefault',
        to: {
          name: 'objects',
          params: {
            groupId: id
          }
        },
        actions: [
          {
            id: 'edit',
            icon: '$edit',
            action: ({ popup }) => {
              popup.open({
                component: () =>
                  import('@/modules/object-groups/ui/ObjectGroupEdit.vue'),
                props: { id: id }
              });
            }
          }
        ]
      })),
      addObjectGroupItem
    ])
  };
}
