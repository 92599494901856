<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { provide, ref, watch } from '@vue/composition-api';
import { useAuth } from '@/modules/auth/api';
import { useObjects } from './modules/objects/compositions/objects';
import { usePlans } from './modules/plans/compositions/plans';
import { useLandmarks } from './modules/landmarks/compositions/landmarks';
import { useGeozones } from './modules/geozones/compositions/geozones';
import { useObjectGroups } from '@/modules/object-groups/compositions/object-groups';
import { useProgramSettings } from '@/modules/account/useProgramSettings';
import { useMap } from '@/compositions/map';
import {
  ALL_GEOTAG_ID,
  ALL_GEOTAG_ITEM,
  useGeotags
} from '@/modules/geotags/compositions/geotags';
import { useTypes } from '@/modules/object-types/compositions/types';
import { useConnections } from '@/modules/connections/compositions/connections';

export default {
  name: 'App',
  setup(props, context) {
    const {
      isLoggedIn,
      getUserId,
      loadNotificationProfiles,
      isAppDataLoaded,
      isAppDataLoading
    } = useAuth();
    const { load: loadObjects } = useObjects();
    const { load: loadPlans } = usePlans();
    const { load: loadLandmarks } = useLandmarks();
    const { load: loadGeozones } = useGeozones();
    const { load: loadGeotags } = useGeotags();
    const { load: loadObjectGroups } = useObjectGroups();
    const { load: loadTypes } = useTypes();
    const { setGeotag, defaultTags } = useGeotags();
    const { load: loadConnections } = useConnections();
    const { load: loadProgramSettings, programSettings } = useProgramSettings();
    const {
      setDefaultCenter,
      setDefaultZoom,
      selectBaseLayer,
      setDefaultRotation,
      setOSMTileUrl
    } = useMap();

    const userId = ref('');

    provide('userId', userId);

    watch(
      () => isLoggedIn.value,
      async v => {
        if (v) {
          try {
            isAppDataLoading.value = true;
            userId.value = await getUserId();

            // loading entities which have no dependencies
            await Promise.all([
              loadObjectGroups(),
              loadGeotags(),
              loadConnections(),
              loadTypes()
            ]);
            await loadProgramSettings();
            await loadNotificationProfiles();
            setMapSettings();

            // loading entities which have dependencies from settings
            await Promise.all([loadObjects(), loadLandmarks(), loadGeozones()]);

            // loading plans at the end
            await loadPlans();
            isAppDataLoaded.value = true;
          } finally {
            console.log('app data loaded');
            isAppDataLoading.value = false;
          }
        }
      }
    );

    const setMapSettings = () => {
      const userZoom = programSettings.value.programDefaultScale.value;
      const userCenter = programSettings.value.programDefaultPosition.value;
      const userMapLayer = programSettings.value.programMap.value;
      const userGeotag = programSettings.value.programDefaultGeotag.value;
      const allowAllGeotags =
        programSettings.value.programAllowAllGeotags.value;
      const userObjectGroup =
        programSettings.value.programDefaultObjectGroup.value;
      const userMapRotation =
        programSettings.value.programDefaultRotation.value;
      const osmlUrl = programSettings.value.programOsmUrl.value;

      if (osmlUrl) {
        setOSMTileUrl(osmlUrl);
      }
      if (userZoom !== undefined) {
        setDefaultZoom(userZoom);
      }
      if (userCenter !== undefined) {
        setDefaultCenter(userCenter);
      }
      if (userMapLayer !== undefined) {
        selectBaseLayer(userMapLayer);
      }
      if (allowAllGeotags) {
        defaultTags.value.push(ALL_GEOTAG_ITEM);

        if (userGeotag) {
          setGeotag(userGeotag);
        } else {
          setGeotag(ALL_GEOTAG_ID);
        }
      }

      if (userGeotag) {
        setGeotag(userGeotag);
      }

      if (userObjectGroup && context.root.$route.name === 'objects') {
        context.root.$router.replace({
          params: { groupId: userObjectGroup }
        });
      }
      if (userMapRotation) {
        setDefaultRotation(userMapRotation);
      }
    };
  }
};
</script>

<style lang="sass">
@import './styles/main.sass'
</style>

<style lang="scss">
.v-data-table.rowHover tr .avatar {
  display: block;
}
.v-data-table.rowHover tr .hidden .checkbox {
  display: none;
}

.v-data-table.rowHover tr:hover .avatar,
.v-data-table.rowHover tr .avatar.hidden {
  display: none;
}
.v-data-table.rowHover tr:hover .checkbox {
  display: block;
}
.v-data-table-header th[role='columnheader'] span {
  font-size: 14px;
  font-weight: 500;
}
.v-data-table__wrapper table tbody tr td.text-start {
  white-space: nowrap;
  text-overflow: ellipsis;
}
.regular16 {
  font-size: 16px !important;
  font-weight: 400 !important;
}
.regular14 {
  font-size: 14px !important;
  font-weight: 400 !important;
}
</style>
